<script setup lang="ts">
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const lastGame = parseJSON(localStorage.getItem("lastGame") || "{}");
</script>
<template>
	<div class="box">
		<div class="img">
			<NuxtImg
				:src="`${baseImageUrl}${lastGame?.cover}`"
				:alt="`${lastGame?.title}`"
				format="webp"
				width="114"
				height="64"
			/>
		</div>
		<div class="info">
			<AText :modifiers="['link', 'underline']">{{ lastGame?.subTitle }}</AText>
			<AText variant="toledo" :modifiers="['medium']">
				<span v-html="lastGame?.title" />
			</AText>
		</div>
	</div>
</template>
<style lang="scss">
#last-game-container {
	--toastify-color-dark: var(--changshu);
	--toastify-text-color-dark: var(--cannes);
	--toastify-toast-max-height: 80px;
	--toastify-z-index: 9999999;

	width: 420px;
	max-width: calc(100% - 16px);
	padding: 0;

	.Toastify__toast {
		padding: 8px;
	}

	.icon-close {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		background: url("~/assets/icons/16/close.svg") center/16px no-repeat;
	}
}
</style>
<style scoped lang="scss">
.box {
	display: flex;
	height: 64px;
	gap: gutter(1.5);
	align-items: center;
}

.info {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);
}

.img {
	width: 113px;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:before {
		content: "";
		display: inline-flex;
		width: 40px;
		height: 40px;
		background: url("/_nuxt/assets/icons/54/play.svg") center/cover no-repeat;
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
	}
}
</style>
